import React, { Component } from 'react'
import { StateContext } from '../../../../util/state.jsx'
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/outline'
import { classNames } from '../../../../util/helpers'
import { withRouter } from 'react-router-dom'
import AvatarWithViewModal from '../../components/AvatarWithViewModal/index.jsx'
import ApproveForTravelModal from '../../modals/ApproveForTravelModal/index.jsx'
import { Button, Dropdown, ConfirmModal } from '../../../../components/Tailwind/index.jsx'
import { clearIdentityForWorker, retrieveWorker, unlinkWorkerFromEmployer } from '../../../../models/organization.jsx'
import { SpinnerDiamond } from 'spinners-react'
import { Menu, Tab } from '@headlessui/react'
import ChangeEmployerModal from '../../modals/ChangeEmployerModal/index.jsx'
import AssignFacilitatorModal from '../../modals/AssignFacilitatorModal/index.jsx'
import WorkerSection from '../Employers/WorkerDetail/workerSection.jsx'
import TripSection from '../Employers/WorkerDetail/tripSection.jsx'

const tabs = [
  { name: 'Profile', index: 0 },
  { name: 'Jobs', index: 1 },
  { name: 'Chat', index: 2 },
]

class WorkerArticle extends Component {
  static contextType = StateContext

  state = {
    assignFacilitatorModalIsOpen: false,
    changeEmployerModalIsOpen: false,
    approveForTravelModalIsOpen: false,
    unlinkWorkerModalIsOpen: false,
    clearIdentityModalIsOpen: false,

    currentTab: 0,
  }

  componentDidMount = () => {
    this.loadWorker()
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.match.params?.workerId !== prevProps.match.params?.workerId) {
      this.loadWorker()
    }
  }

  pullLatestAfterUpdate = async () => {
    this.loadWorker()
  }

  loadWorker = async () => {
    const [{ firebaseUser, user }, dispatch] = this.context
    try {
      dispatch({ type: 'setIsLoadingSelectedWorker', isLoadingSelectedWorker: true })
      const worker = await retrieveWorker(firebaseUser, user.fields?.organization?.id, this.props.match.params?.workerId)
      dispatch({
        type: 'setSelectedWorker',
        selectedWorker: worker,
        isLoadingSelectedWorker: false,
      })
      dispatch({ type: 'updateWorker', worker: worker })
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to get worker.`, waitingOnAPIResult: false },
        showNotification: true,
      })
    }
  }

  handleUnlinkWorker = async () => {
    const [{ firebaseUser, employerWorkersAndMeta, organization }, dispatch] = this.context
    const { workerId, employerId } = this.props.match.params
    const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

    this.setState({ waitingOnAPIResult: true })

    try {
      await unlinkWorkerFromEmployer(firebaseUser, organization.id, workerId)
      this.setState({ waitingOnAPIResult: false })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Removed worker.`, success: true },
        showNotification: true,
      })
      this.setUnlinkWorkerModalIsOpen(false)
      dispatch({ type: 'removeWorker', workerId: workerId })
      // this.props.history.push('../summary')
      // this.props.listWorkersForOrganizationAndEmployer(employerId, filtersAndPagination)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Failed to remove worker.', content: `${error.message}`, success: false },
        showNotification: true,
      })
      return
    }
  }

  handleClearIdentity = async () => {
    const [{ user, organization }, dispatch] = this.context
    this.setState({ loading: true })
    await clearIdentityForWorker(organization?.id, this.props.match.params?.workerId)
    this.setState({ loading: false })
    this.setClearIdentityModalIsOpen(false)
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: 'Success!', content: `Cleared identity.`, success: true },
      showNotification: true,
    })
  }

  setUnlinkWorkerModalIsOpen = open => this.setState({ unlinkWorkerModalIsOpen: open })
  toggleAssignFacilitatorModal = open => this.setState({ assignFacilitatorModalIsOpen: open })
  toggleChangeEmployerModal = open => this.setState({ changeEmployerModalIsOpen: open })
  toggleApproveForTravelModal = open => this.setState({ approveForTravelModalIsOpen: open })
  setClearIdentityModalIsOpen = open => this.setState({ clearIdentityModalIsOpen: open })

  render() {
    const [{ selectedWorker, isLoadingSelectedWorker }] = this.context

    if (isLoadingSelectedWorker) {
      return (
        <div className='flex justify-center items-center w-full min-h-screen text-center'>
          <div className='block absolute top-0 left-0 z-50 w-full h-full bg-white opacity-75'>
            <span className='block relative top-1/2 mx-auto w-0 h-0 opacity-75'>
              <SpinnerDiamond className='-mt-6 -ml-6' color='#54537A' />
            </span>
          </div>
        </div>
      )
    }

    if (!selectedWorker && !isLoadingSelectedWorker) {
      return (
        <div className='flex justify-center items-center w-full min-h-screen text-center'>
          <div>
            <SearchIcon className='mx-auto w-10 h-10 text-gray-400' />
            <h3 className='mt-2 text-sm font-medium text-gray-900'>No worker selected</h3>
            <p className='mt-1 text-sm text-gray-500'>Select a worker from the directory.</p>
          </div>
        </div>
      )
    }

    const hiddenIfReadOnly = () => (this.props.readonly ? 'hidden' : '')

    console.log(selectedWorker)
    return (
      <article>
        {/* Profile header */}
        <div>
          <div>
            <img className='object-cover w-full h-32 lg:h-48' src={'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'} alt='' />
          </div>
          <div className='px-4 mx-auto max-w-5xl sm:px-6 lg:px-8'>
            <div className='-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5'>
              <div className='flex relative'>
                <AvatarWithViewModal height={32} width={32} className='ring-4 ring-white' worker={selectedWorker} />
              </div>
              <div className='mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1'>
                <div className='flex-1 mt-6 min-w-0 sm:hidden 2xl:block'>
                  <h1 className='text-2xl font-bold text-gray-900 truncate'>{selectedWorker?.fields?.displayName}</h1>
                  {selectedWorker?.fields?.approvedForTravel === 'YES' ? (
                    <dd className='flex items-center mt-3 text-sm font-bold text-green-500 capitalize sm:mr-6 sm:mt-0'>
                      <>
                        <CheckCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400' /> Approved for travel
                      </>
                    </dd>
                  ) : (
                    <dd className='flex items-center mt-3 text-sm font-bold text-red-500 capitalize sm:mr-6 sm:mt-0'>
                      <>
                        <XCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400' /> Not approved for travel
                      </>
                    </dd>
                  )}
                </div>
                <div className='flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4'>
                  <Dropdown label='Manage' className={`z-50 ${hiddenIfReadOnly()}`}>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => this.setUnlinkWorkerModalIsOpen(true)} className={classNames(active ? 'text-red-900 bg-gray-100' : 'text-red-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                          Remove
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => this.toggleAssignFacilitatorModal(true)} className={classNames(active ? 'text-gray-900 bg-gray-100' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                          Assign Facilitator
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => this.toggleChangeEmployerModal(true)} className={classNames(active ? 'text-gray-900 bg-gray-100' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                          Change Employer
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => this.setClearIdentityModalIsOpen(true)} className={classNames(active ? 'text-red-900 bg-gray-100' : 'text-red-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                          Clear Identity
                        </a>
                      )}
                    </Menu.Item>
                  </Dropdown>
                  <ConfirmModal
                    title='Clear Login Info'
                    content='Are you sure you want to clear this workers identity? This will remove email, phone, and their ability to login. You must then update their information again to use Ruta.'
                    buttonLabel='Clear'
                    open={this.state.clearIdentityModalIsOpen}
                    setOpen={this.setClearIdentityModalIsOpen}
                    actionOnClick={this.handleClearIdentity}
                    loading={this.state.waitingOnAPIResult}
                  />
                  <ConfirmModal
                    title='Remove Worker'
                    content='Are you sure you remove this worker? All their details will be deleted and they will not be able to login to Ruta. '
                    buttonLabel='Remove'
                    open={this.state.unlinkWorkerModalIsOpen}
                    setOpen={this.setUnlinkWorkerModalIsOpen}
                    actionOnClick={this.handleUnlinkWorker}
                  />
                  <ChangeEmployerModal
                    initialEmployerId={selectedWorker?.fields?.employer?.id || undefined}
                    open={this.state.changeEmployerModalIsOpen}
                    setOpen={this.toggleChangeEmployerModal}
                    pullLatestAfterUpdate={this.pullLatestAfterUpdate}
                  />
                  <AssignFacilitatorModal
                    assignedFacilitatorUserId={selectedWorker?.fields?.assignedFacilitatorUserId || undefined}
                    open={this.state.assignFacilitatorModalIsOpen}
                    setOpen={this.toggleAssignFacilitatorModal}
                    pullLatestAfterUpdate={this.pullLatestAfterUpdate}
                  />

                  {selectedWorker?.fields?.approvedForTravel === 'NO' && (
                    <Button onClick={() => this.toggleApproveForTravelModal(true)} className={`${hiddenIfReadOnly()}`}>
                      Approve for travel
                    </Button>
                  )}
                  <ApproveForTravelModal open={this.state.approveForTravelModalIsOpen} setOpen={this.toggleApproveForTravelModal} pullLatestAfterUpdate={this.pullLatestAfterUpdate} worker={selectedWorker} />
                </div>
              </div>
            </div>
            <div className='hidden flex-1 mt-6 min-w-0 sm:block 2xl:hidden'>
              <h1 className='text-2xl font-bold text-gray-900 truncate'>{selectedWorker?.fields?.displayName}</h1>
            </div>
          </div>
        </div>

        {!selectedWorker?.fields?.assignedFacilitator && !this.props.readonly && (
          <div className='px-4 mx-auto space-y-4 max-w-5xl sm:px-6 lg:px-8'>
            <div className={`p-4 mt-4 bg-yellow-50 border-l-4 border-yellow-400`}>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  <InformationCircleIcon className='w-5 h-5 text-yellow-400' aria-hidden='true' />
                </div>
                <div className='ml-3'>
                  <h3 className='text-sm font-medium text-yellow-700'>This worker is not assigned to a facilitator. </h3>
                  <div className='mt-4'>
                    <div className='-mx-2 -my-1.5 flex'>
                      <button
                        type='button'
                        onClick={() => this.toggleAssignFacilitatorModal(true)}
                        className='bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium border-yellow-400 border text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600'
                      >
                        Assign Facilitator
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Tabs */}

        <Tab.Group defaultIndex={this.state.currentTab} onChange={index => this.setState({ currentTab: index })}>
          <div className='mt-6 sm:mt-2 2xl:mt-5'>
            <div className='border-b border-gray-200'>
              <div className='px-4 mx-auto sm:px-6'>
                <Tab.List className='flex -mb-px space-x-8'>
                  {tabs.map(tab => (
                    <Tab
                      key={tab.name}
                      className={classNames(
                        tab.index === this.state.currentTab ? 'border-pink-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={tab.index === this.state.currentTab ? 'page' : undefined}
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
            </div>
          </div>

          <Tab.Panels>
            <Tab.Panel>
              {/* Worker Info */}
              <WorkerSection readonly={this.props.readonly} worker={selectedWorker} pullLatestAfterUpdate={this.pullLatestAfterUpdate} />
            </Tab.Panel>

            <Tab.Panel>
              {/* Jobs */}
              <section aria-labelledby='applicant-information-title'>
                <div className=''>
                  <div className='flex flex-wrap justify-between items-center px-4 py-4 sm:px-6 sm:flex-nowrap'>
                    <div className='mt-2'>
                      <h2 className='text-lg font-medium leading-6 text-gray-900'>
                        Jobs
                        <p className='text-sm text-gray-500'>Jobs this worker is on. </p>
                      </h2>
                    </div>
                    <div className='flex-shrink-0 ml-4'></div>
                  </div>

                  <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
                    <div className='px-4 py-5 border-t border-gray-200 sm:p-0'>
                      <dl className='sm:divide-y sm:divide-gray-200'>
                        <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                          {selectedWorker?.fields?.jobs?.map(job => (
                            <>
                              <dt className='text-sm font-medium text-gray-500'>{job?.job?.employer?.name} {job?.job?.season}</dt>
                              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                                <a className='text-blue-400 cursor-pointer' onClick={() => this.props.history.push(`/facilitator/jobs/${job?.job?.id}`)}>Go to job</a>
                              </dd>
                            </>
                          ))}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </section>
            </Tab.Panel>

            <Tab.Panel>Content 3</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </article>
    )
  }
}

export default withRouter(WorkerArticle)
