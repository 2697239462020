import axios from 'axios'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const retrieveOrganization = async (sessionToken, organizationId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const patchOrganization = async (firebaseUser, organizationId, orgData) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}`,
      data: orgData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const retrieveDashboard = async (organizationId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/dashboard`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listEmployersForOrganization = async (sessionToken, organizationId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employers?`
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
    }
    if (filtersAndPagination?.pageSize) {
      url += `pageSize=${filtersAndPagination.pageSize}&`
    } else {
      url += `pageSize=1000&`
    }
    if (filtersAndPagination?.employerId) {
      url += `employerId=${filtersAndPagination.employerId}&`
    }
    if (filtersAndPagination?.searchText) {
      url += `searchText=${filtersAndPagination.searchText}&`
    }    
    if (filtersAndPagination?.updatesInLastXDays) {
      url += `updatesInLastXDays=${filtersAndPagination?.updatesInLastXDays}&`
    }        
    
    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listInvitationsForOrganization = async (firebaseUser, organizationId, employerId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/invitations?`
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
    }
    if (employerId) {
      url += `recipientEmployerId=${employerId}&`
    }
    
    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listEmployeesForOrganization = async (firebaseUser, organizationId, facilitatorUserId) => {
  try {
    let url = facilitatorUserId ? `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employees?facilitatorUserId=${facilitatorUserId}` : 
      `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employees`
    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listTripsForOrganizationAndEmployer = async (firebaseUser, organizationId, employerId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/trips?employerId=${employerId}&pageSize=5&`
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
    }
    // if (filtersAndPagination?.someKey) {
    //   url += `someKey=${filtersAndPagination.someKey}&`
    // }

    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listWorkersForOrganizationAndEmployer = async (firebaseUser, organizationId, employerId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers?employerId=${employerId}&pageSize=300&`
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
    }
    // if (filtersAndPagination?.someKey) {
    //   url += `someKey=${filtersAndPagination.someKey}&`
    // }

    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const listWorkers = async (firebaseUser, organizationId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers?pageSize=10000&`
    if (filtersAndPagination?.employerId) {
      url += `employerId=${filtersAndPagination?.employerId}&`
    }
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
    }

    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const listJobs = async (organizationId, filtersAndPagination) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs?`
    if (filtersAndPagination?.currentPage) {
      url += `currentPage=${filtersAndPagination.currentPage}&`
      url += `pageSize=${filtersAndPagination.pageSize}&`
    } else if (filtersAndPagination?.searchText) {
      url += `searchText=${filtersAndPagination.searchText}&`
    } else {
      url += `currentPage=1&pageSize=1000&`
    }
    let result = await axios.get(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const retrieveJob = async (organizationId, jobId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}`
    let result = await axios.get(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteJob = async (organizationId, jobId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}`
    let result = await axios.delete(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createJob = async (organizationId, job) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs`
    let result = await axios.post(url, job)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const addWorkerToJob = async (organizationId, jobId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}`
    let result = await axios.post(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const removeWorkerFromJob = async (organizationId, jobId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}`
    let result = await axios.delete(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const updateWorkerStatusForJob = async (organizationId, jobId, workerId, data) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/status`
    let result = await axios.patch(url, data)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const sendContractReminder = async (organizationId, jobId, workerId, data) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/sendContractReminder`
    let result = await axios.post(url, data)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const sendRegistrationReminder = async (organizationId, jobId, workerId, data) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/sendRegistrationReminder`
    let result = await axios.post(url, data)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const addNoteForJob = async (organizationId, jobId, workerId, data) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/notes`
    let result = await axios.post(url, data)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteNoteForJob = async (organizationId, jobId, workerId, noteId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/notes/${noteId}`
    let result = await axios.delete(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const generateContractForWorkerOnJob = async (organizationId, jobId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/generateContract`
    let result = await axios.post(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const generateSignUrlForWorkerOnJob = async (organizationId, jobId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/generateSignUrl`
    let result = await axios.post(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const sendJobReportEmailToMember = async (organizationId, jobId, employerId, memberId) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/sendJobReportEmailToMember`,
      data: {
        memberId,
        employerId
      }
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const listDocumentsInContract = async (organizationId, jobId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/contractDocuments`
    let result = await axios.get(url)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const downloadContractForWorkerOnJob = async (organizationId, jobId, workerId, downloadURL) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/workers/${workerId}/downloadContract`
    if (downloadURL) {
      url = `${url}?downloadURL=${downloadURL}`
    }
    let result = await axios.get(url, {responseType: 'blob'})
    return result
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const patchJob = async (organizationId, jobId, data) => {
  try {
    let result = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}`,
      data: data
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const downloadJobReport = async (organizationId, jobId) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/jobs/${jobId}/report`
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const retrieveWorker = async (firebaseUser, organizationId, workerId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/${workerId}`

    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const downloadReportForWorker = async (firebaseUser, organizationId, workerId, tripId) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/${workerId}/export?tripId=${tripId}`
    let result = await axios({
      method: 'get',
      responseType: "blob",
      url: url,
    })
    //Create a Blob from the PDF Stream
    const file = new Blob([result.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    let a = document.createElement('a');
    a.href = fileURL;
    a.download = 'worker-report.pdf';
    window.open(fileURL, '_blank').focus();

    // const pdfWindow = window.open();
    // pdfWindow.location.href = fileURL;      
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const approveWorkerForTravel = async (firebaseUser, organizationId, workerId, approvingUserSignature) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/${workerId}/approve`,
      data: { 
        approvedForTravel: 'YES',
        approvingUserSignature: approvingUserSignature
      }
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const findWorkerForOrganizationAndEmployer = async (firebaseUser, organizationId, employerId, searchText) => {
  try {
    let url = `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/find?employerId=${employerId}&searchText=${searchText}`
    let result = await axios({
      method: 'get',
      url: url,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const retrieveTripsForEmployee = async (firebaseUser, organizationId, employeeId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employees/${employeeId}/trips`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createEmployer = async (firebaseUser, organizationId, employerData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employers`,
      data: employerData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createMemberForEmployer = async (employerId, data) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/employers/${employerId}/members`,
      data
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteEmployer = async (firebaseUser, organizationId, employerId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employers/${employerId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const unlinkWorkerFromEmployer = async (firebaseUser, organizationId, workerId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/${workerId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const clearIdentityForWorker = async (organizationId, workerId) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/workers/${workerId}/clearIdentity`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const bulkSetFacilitatorForEmployer = async (firebaseUser, organizationId, employerId, assignedFacilitatorUserId) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/employers/${employerId}/setFacilitatorUser`,
      data: { assignedFacilitatorUserId: assignedFacilitatorUserId}
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteTripForOrganization = async (firebaseUser, organizationId, tripId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/trips/${tripId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const sendExpenseReminders = async (firebaseUser, organizationId, employerId) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/organizations/${organizationId}/sendExpenseReminders?employerId=${employerId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}